{
    "langue": "Francais",

    "install": {
        "title": "Installe l'App TWS!",
        "text": "TrueWindSpeed s'installe facilement sur ordi ou téléphone. L'App ne prend aucune place, car c'est une Webapp!",
        "button": "Clic pour installer L'App !"
    },

    "contact": {
        "phone": "Téléphone",
        "mail": "Mail",
        "button": "Clic pour installer L'App !"
    },

    "nav": {
        "Direct": "Direct",
        "Map": "Carte",
        "Montre": "Montre"
    },

    "info": {

        "legend":{
            "header": "Légendes",
            "varbl": "Variable",
            "descr": "Description",

            "wbt": "Température humide naturelle",
            "tg": "Température de globe noir",
            "ta": "Température de l'air",
            "rh": "Humidité relative",

            "wbgt-M": "WBGT - Métabolisme",

            "redlimit": "Limite personnes acclimatées",
            "orangelimit": "Limite personnes non acclimatées",
            "safezone": "Zone de sécurité",
            "coldzone": "Zone froide",
            "coldnote": "attention à la mise en oeuvre de certains matériaux",
            "M": "Estimation du métabolisme énergétique",
            "CAV": "Valeur d'ajustement pour les vêtements"            
        },

        "M":{
            "#0": "Repos",
            "#1": "Faible",
            "#2": "Modéré",
            "#3": "Élevé",
            "#4": "Très élevé"
        },
        "ME":{
            "header":"Classification des niveaux de métabolisme énergétique selon l'ISO 8996",
            "title":{
                "#0": "Repos",
                "#1": "Métabolisme énergétique faible",
                "#2": "Métabolisme énergétique modéré",
                "#3": "Métabolisme énergétique élevé",
                "#4": "Métabolisme énergétique très élevé"
            },
            "text":{
                "#0": "Repos, position assise confortable",
                "#1": "Travail manuel léger (écriture, frappe à la machine, dessin, couture, comptabilité); travail des mains et des bras (petits outils d'établi, inspection, assemblage ou triage de matériaux légers); travail des bras et des jambes (conduite de véhicule dans des conditions normales, manœuvre d'un interrupteur à pied ou d'une pédale).\n\nDebout: perceuse (petites pièces); fraiseuse (petites pieces); bobinage;\nenroulement de petites armatures; usinage avec outlis de faible puissance; marche occasionnelle sur une surface plane (vitesse jusqu'à 2,5 km/h).",
                "#2": "Travail soutenu des mains et des bras (cloutage, limage); travail des bras et des jambes (manœuvre sur chantiers de camions, tracteurs ou engins); travall des bras et du tronc (travail au marteau pneumatique, accouplement de véhicules, plâtrage, manipulation intermittente de matériaux modérément lourds, sarclage, binage, cueillette de fruits ou de légumes; poussée ou traction de charrettes légères ou de brouettes; marche à une vitesse de 2,5 à 5,5 km/h sur une surface plane; forgeage).",
                "#3": "Travail intense des bras et du tronc; transport de matériaux lourds; pelletage; travail au marteau; sciage, planage ou ciselage de bois dur; fauchage manuel; excavation; marche à une vitesse de 5,5 à 7 km/h sur une surface plane.\n\nPoussée ou traction de charrettes à bras ou de brouettes lourdement chargées; enlèvement de copeaux de pièces moulées; pose de blocs de béton.",
                "#4": "Activité très intense à allure rapide proche du maximum; travail à la hache; pelletage ou excavation à un rythme intensif; action de monter des escaliers, une rampe ou une échelle; marche rapide à petits pas; course sur une surface plane; marche à une vitesse supérieure à 7 km/h sur une surface plane."
            },
            "richtext":{
                "#0": "Repos, position assise confortable",
                "#1": "Travail manuel léger (écriture, frappe à la machine, dessin, couture, comptabilité); travail des mains et des bras (petits outils d'établi, inspection, assemblage ou triage de matériaux légers); travail des bras et des jambes (conduite de véhicule dans des conditions normales, manœuvre d'un interrupteur à pied ou d'une pédale).<br/><br/> Debout: perceuse (petites pièces); fraiseuse (petites pieces); bobinage;<br/> enroulement de petites armatures; usinage avec outlis de faible puissance; marche occasionnelle sur une surface plane (vitesse jusqu'à 2,5 km/h).",
                "#2": "Travail soutenu des mains et des bras (cloutage, limage); travail des bras et des jambes (manœuvre sur chantiers de camions, tracteurs ou engins); travall des bras et du tronc (travail au marteau pneumatique, accouplement de véhicules, plâtrage, manipulation intermittente de matériaux modérément lourds, sarclage, binage, cueillette de fruits ou de légumes; poussée ou traction de charrettes légères ou de brouettes; marche à une vitesse de 2,5 à 5,5 km/h sur une surface plane; forgeage).",
                "#3": "Travail intense des bras et du tronc; transport de matériaux lourds; pelletage; travail au marteau; sciage, planage ou ciselage de bois dur; fauchage manuel; excavation; marche à une vitesse de 5,5 à 7 km/h sur une surface plane.<br/><br/> Poussée ou traction de charrettes à bras ou de brouettes lourdement chargées; enlèvement de copeaux de pièces moulées; pose de blocs de béton.",
                "#4": "Activité très intense à allure rapide proche du maximum; travail à la hache; pelletage ou excavation à un rythme intensif; action de monter des escaliers, une rampe ou une échelle; marche rapide à petits pas; course sur une surface plane; marche à une vitesse supérieure à 7 km/h sur une surface plane."
            },
            "#0": "Repos",
            "#1": "Métabolisme énergétique faible",
            "#2": "Métabolisme énergétique modéré",
            "#3": "Métabolisme énergétique élevé",
            "#4": "Métabolisme énergétique très élevé"
        },


        "CAV":{
            "header": "Valeurs d'ajustement de l'indice WBGT pour les vêtements (CAV) pour différentes tenues vestimentaires en °C-WBGT",
            "title":{
                "sc": "Vêtements sans cagoule",
                "sc_a": "Vêtements de travail",
                "sc_b": "Combinaisons",
                "sc_c": "Combinaisons en SMS non tissé en une seule couche",
                "sc_d": "Combinaisons en polyoléfine non tissée en une seule couche",
                "sc_e": "Tablier pare-vapeur à manches longues et combinaisons longues",
                "sc_f": "Double couche de vêtements tissés",
                "sc_g": "Combinaisons pare-vapeur en une seule couche, sans cagoule",
                "sc_h": "Pare-vapeur au-dessus de combinaisons, sans cagoule",
    
                "ac": "Vêtements avec cagoule",
                "ac_a": "Vêtements de travail + cagoule",
                "ac_b": "Combinaisons + cagoule",
                "ac_c": "Combinaisons en SMS non tissé en une seule couche + cagoule",
                "ac_d": "Combinaisons en polyoléfine non tissee en une seule couche + cagoule",
                "ac_e": "Tablier pare-vapeur à manches longues et combinaisons longues + cagoule",
                "ac_f": "Double couche de vêtements tissés + cagoule",
                "ac_g": "Combinaisons pare-vapeur à cagoule en une seule couche",

                "c": "Cagoule"
            },
            "text":{
                
                "sc_a": "Des vêtements de travail en étoffe tissée sont la tenue de référence.",
                "sc_b": "Étoffe tissée contenant du coton traité.",
                "sc_c": "Procédé non propriétaire pour fabriquer des étoffes non tissées à partir de polypropylène.",
                "sc_d": "Étoffe propriétaire fabriquée à partir de polyéthylène.",
                "sc_e": "La configuration de tablier enveloppant a été conçue pour protéger la partie antérieure et les parties latérales du corps contre les déversements accidentels d'agents chimiques.",
                "sc_f": "Généralement considérés comme des combinaisons au-dessus de vêtements de travail.",
                "sc_g": "L'effet réel dépend du niveau d'humidité et, dans bien des cas, l'effet est moindre.",
                "sc_h": "-",

                "ac_g": "L'effet réel dépend du niveau d'humidité et, dans bien des cas, l'effet est moindre.",

                "c": "Port d'une cagoule en étoffe avec n'importe quelle tenue vestimentaire. Cette valeur est ajoutée à la valeur CAV de la tenue sans cagoule ni appareil respiratoire.",
                
                "note": "NOTE: Pour les vêtements à forte résistance à l'évaporation, il existe une dépendance par rapport à l'humidité relative. Les valeurs CAV représentent la valeur élevée probable."
            },

            "richtext":{
                
                "c": "Port d'une cagoule en étoffe avec n'importe quelle tenue vestimentaire.<br/><br/><i>Cette valeur est ajoutée à la valeur CAV de la tenue sans cagoule ni appareil respiratoire.</i>",
                
                "note": "<i>NOTE: Pour les vêtements à forte résistance à l'évaporation, Il existe une dépendance par rapport à l'humidité relative. Les valeurs CAV représentent la valeur élevée probable.</i>"
            }
        }
    },

    

    "Chart": {
        "spotselect": "Séléctioner un spot",
        "spotnotfound": "Spot non trouvé",

        "Metabolisme": "Métabolisme",

        "start": "Début",
        "end": "Fin"
        
    },


    "Map": {
        "h":{
            "Options": "Options",
            "Map": "Map",
            "swisslayer": "couches supplémentaires"
        },
        "button":{
            "Wind": "Vent",
            "WBGT": "WBGT",
            "Temp": "Temp.",
            "Temperature": "Température",
            "Hum": "Humidité",
            "Press": "Pression",
            "Lux": "Lux",
            "Water": "Eau",
            "Wave": "Vague",
            "Period": "Période",
            "Tide": "Marée"
        }

    },


    "ExportButton": {
        "export": "Exporter",
        "csv": "Export CSV",
        "excel": "Export Excel",
        "pdf": "Export PDF",
        "pdf_hot": "Export PDF - Chaleur",
        "pdf_cold": "Export PDF - Froid"
    },

    "Certif":{

        "cert_clim": "Certificat climatique",
        "cert": "Certificat",
        "hot": "Fortes chaleures",
        "cold": "Grand froid",
        "lab": "Mesures éffectuées avec des capteurs de température WBGT¹ construits sur la base de la norme ISO 7243, en cours de contôle² par un laboratoire accrédité ISO 1725.",

        "comp": "Entreprise",
        "ide": "IDE",
        "lieu": "Nom du lieu",
        "long": "Longitude",
        "lat": "Latitude",
        "date": "Date d'émission du certificat",
        "start": "Début",
        "end": "Fin",
        "metabo": "Métabolisme énergétique",
        "cav": "CAV⁴ (vêtements)",

        "exmp": "Exemple",
        "comm": "Commentaire",
        "shirt": "Vêtement",
        

        "bp1": "¹ L'indice de température WBGT quantifie le stresse thermique sur l'organisme, lié à la température, l'humidité et le rayonement solaire directe et indirect.",
        "bp2": "² Sur demande, nous pouvons vous présenter notre protocole de mesures et nos procédures de contrôle qualité.",

        "voir": "Voir",
        "Anx_a": "Annexes A",
        "Anx_b": "Annexes B",

        "redPeriode": "Période(s) au-delà de la limite personnes acclimatées",
        "orangePeriode": "Période(s) au-delà de la limite personnes acclimatées",
        "coldPeriode": "Période(s) de température inférieure à 5°C",



        "csvxls":{
            "date": "Date",
            "limAcc": "Limite Acclimaté °C",
            "limNAcc": "Limite non-Acclimaté °C",
            "temp": "Température"
        }




    }


}
  